.sidebar-menu {
	display: table-cell;
	position: relative;
	width: @sidebar-width;
	background: @sidebar-background;
	z-index: 1;

	.logo-env {
		padding: @base-margin (@double-margin + @sidebar-horizontal-link-padding);
		border-bottom: 1px solid @sidebar-menu-border;
		background-color: @logo-bg;
		height: 55px;
		&:extend(.clearfix all);

		.logo {
			float: left;

			.logo-collapsed {
				display: none;
			}
		}

        //添加logo图片大小控制
        .logo-expanded img{
          width:80px;
          height:31px;
        }
		.settings-icon {
			float: right;
			position: relative;
			top: 1px;

			a {
				@s: 18px;

				color: @main-text-color;
				line-height: 1;

				i {
					line-height: 1;
					font-size: @s;
				}

				&:hover {
					color: @sidebar-text-active-color;
				}
			}
		}
	}


	// Main Menu
	.main-menu {
		padding-left: @double-margin;
		padding-right: @double-margin;
		margin-top: @base-margin;
		margin-bottom: @base-margin + 40;
		list-style: none;

		@media screen and (max-width: @screen-sm){
			margin-top: @base-padding;
			margin-bottom: @base-padding;
		}

		// Links
		a {
			color: @sidebar-text-color;//@main-text-color;
			text-decoration: none;
			display: block;
			padding: @sidebar-vertical-link-padding @sidebar-horizontal-link-padding;
			//border-bottom: 1px solid @sidebar-menu-border;
			&:extend(.transall);

			> i {
				display: inline-block;
				margin-right: @base-padding;
				width:@base-padding*3 + 1;
				text-align: center;

				+ span {
					padding-right: @base-padding;
				}
			}

			> span {
				display: inline-block;
			}

			> .label {
				margin-top: 1px;
			}

			> .badge {
				font-size: 75%;
				line-height: 1.2;
			}

			&:hover {
				color: @sidebar-text-active-color;
			}
		}

		// Active
		li.active > a {
			color: @sidebar-text-active-color;
		}

		// With Subs
		li.has-sub {

			> a {

				&:before {
					position: relative;
					display: inline-block;
					font-family: 'FontAwesome';
					content: '\f107';
					float: right;
					color: @sidebar-text-color;//lighten(@sidebar-menu-border, 25%);
					margin-left: @base-padding;
					.rotate(-90deg);
					.transition(~"all 150ms ease-in-out");
				}
			}

			&.opened, &.expanded {

				> a:before {
					.rotate(0deg);
				}
			}
		}

		// First Level
		> li {

			&.opened {

				> ul {
					display: block;
				}

				.opened {

					> ul {
						display: block;
					}
				}
			}
		}

		// Subs
		ul {
			@sub-spacing: 25px;

			display: none;
			margin: 0;
			padding: 0;
			overflow: hidden;
			list-style: none;

			li {
				@show-delay: 80ms;

				position: relative;

				&.is-hidden {

					@media screen and (min-width: @screen-sm){
						left: -@base-margin;
						.opacity(0);
					}
				}

				&.is-shown {
					left: 0;
					.opacity(1);
					.transition(~"all 200ms ease-in-out");

					+ .is-shown { .transition-delay(@show-delay * 1); }
					+ .is-shown + .is-shown { .transition-delay(@show-delay * 1.5); }
					+ .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 2); }
					+ .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 2.5); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 3); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 3.5); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 4); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 4.5); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 5); }
					+ .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown { .transition-delay(@show-delay * 5.5); }
				}

				&.hidden-item {
					visibility: hidden;
					.opacity(0);
					.transition(~"all 250ms ease-in-out");
				}
			}

			// Second Level
			li a { padding-left: @base-padding + @sub-spacing * 1; }

			// Third Level
			li ul li a { padding-left: @base-padding + @sub-spacing * 2; }

			// Fourth Level
			li ul li ul li a { padding-left: @base-padding + @sub-spacing * 3; }

			// Fifth Level
			li ul li ul li ul li a { padding-left: @base-padding + @sub-spacing * 4; }
		}
	}

	.main-open {
		width: @sidebar-width;
		display: inline-block;
		z-index: 3;
		position: fixed;
		bottom: 0px;
		left: 0;
		text-align: right;
		background: @sidebar-background-logo;
		padding: 10px  (@double-margin + @sidebar-horizontal-link-padding);
		&.on{
			> span.fixed-sidebar{
				display: inline-block;
				i{
					right: 10px;
					top: 9px;
					position: absolute;
					width: 24px;
					height: 24px;
					border-radius: 100%;
					text-align: center;
					line-height: 24px;
					box-sizing: border-box;
					opacity: 0.1;
					background:#09338b;
					-webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
					-webkit-animation-name: breathe;
					-webkit-animation-duration: 1500ms;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-direction: alternate;
				}
			}
		}
		> span.fixed-sidebar {
			color: @sidebar-text-active-color;
		}
	}

	@-webkit-keyframes breathe {
		0% { opacity: .6; box-shadow:0 0px 2px rgba(255,255,255,0.2);}
		100% { opacity: 1;  box-shadow:0 0px 10px rgb(255, 255, 255);}
	}

	// Scrollbar
	.ps-scrollbar-x-rail .ps-scrollbar-x,
	.ps-scrollbar-y-rail .ps-scrollbar-y {
		background-color: fade(#fff, 60%);
	}


	// Fixed sidebar
	&.fixed {
		//position: fixed;
		height: 100%;

		.sidebar-menu-inner {
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			width: inherit;
			overflow: hidden;

			.page-body.right-sidebar & {
				left: auto;
				right: 0;
			}
		}
	}


	// Collapsed sidebar
	@media screen and (min-width: @screen-sm) {

		&.collapsed {
			width: @sidebar-collapsed-width;
			z-index: 10;
			overflow: visible;

			.hidden-collapsed {
				display: none !important;
			}

			+ .main-content {

				.user-info-navbar {

					.user-info-menu {

						a[data-toggle="sidebar"] {
							color: @userinfo-link-active-color;
						}
					}
				}

				.main-footer {
					left: @sidebar-collapsed-width;
				}
			}

			&.fixed {
			}

			.sidebar-menu-inner {
				overflow: visible;
				position: relative;
			}

			.logo-env {
				@vpadd: 9px;

				padding: @vpadd 0;

				.logo-collapsed {
					display: block;
					text-align: center;

					img {
						display: inline-block;
						//设置logo图片宽高
						width:40px;
						height:37px;
					}
				}

				.logo-expanded, .settings-icon {
					display: none;
				}

				.logo {
					float: none;
				}
			}

			.main-menu {
				padding-left: 0;
				padding-right: 0;

				> li {
					text-align: center;
					position: relative;

					&.active, li.active {
						background-color: darken(@sidebar-background, 3%);
					}

					> a {

						> i {
							margin-right: 0;
							font-size: 16px;
						}

						> span {
							display: none;

							&.label {
								display: block;
								position: absolute;
								right: 0;
								top: 0;
							}
						}
					}

					&.has-sub {

						> a:before {
							display: none;
						}
					}

					&.opened {

						> ul {
							display: none;
						}
					}

					> ul {
						position: absolute;
						background: @sidebar-background;
						width: @sidebar-collapsed-submenu-width;
						top: 0;
						left: 100%;
						text-align: left;
						//display: block;

						.page-body.right-sidebar & {
							left: auto;
							right: 100%;
						}

						// First level
						> li > a {
							padding-left: @base-margin;
						}

						> li > ul > li > a {
							padding-left: @base-margin + 15 * 1;
						}

						> li > ul > li > ul > li > a {
							padding-left: @base-margin + 15 * 2;
						}

						> li > ul > li > ul > li > ul > li > a {
							padding-left: @base-margin + 15 * 3;
						}

						> li > ul > li > ul > li > ul > li > ul > li > a {
							padding-left: @base-margin + 15 * 4;
						}

						li.has-sub {

							> a {

								&:before {
									margin-right: @base-padding;
								}
							}
						}
					}

					&:hover > ul {
						display: block;
					}
				}
			}

			.main-open {
				width: @sidebar-collapsed-width;
				text-align: center;
				&.on>span.fixed-sidebar i {
					right: 17px;
				}
			}
		}
	}
}


// Right sidebar
.page-body.right-sidebar {

	.page-container {
		direction: rtl;

		> * {
			direction: ltr;
		}
	}
}