#chat {
	@padding_h: 35px;
	@padding_v: 30px - 1;
	@border: #ededed;
	
	@status-offline: darken(@default-border-color, 15%);
	
	
	@main_color: #FFF;
	@menu_active_color: #111;
	
	display: table-cell;
	position: relative;
	z-index: 100;
	width: @chat-width;
	background: @main_color;
	vertical-align: top;
	display: none;
	
	body.chat-open & {
		display: table-cell;
	}
	
	
	&.fixed {
		//position: fixed;
		//min-height: 0 !important;
		
		.chat-inner {
			position: fixed;
			right: 0;
			width: inherit;
			
			.page-body.right-sidebar & {
				right: auto;
				left: 0;
			}
		}
	}
	
	.chat-inner {
		overflow: auto;
		height: 100%;
		border-left: 1px solid @default-border-color;
		
		.page-body.right-sidebar & {
			border-left: 0;
			border-right: 1px solid @default-border-color;
		}
		
		&.ps-container {
			overflow: hidden;
		}
	}
	
	.chat-header {
		font-size: 16px;
		color: @main-text-color;
		padding: @padding_v @padding_h;
		padding-bottom: 0;
		line-height: 1;
		margin: 0;
		position: relative;
		
		
		.badge {
			position: relative;
			top: -1px;
			margin-left: 5px;
			.transform(~"scale(1)");
			.opacity(1);
			.transall;
			
			&.is-hidden {
				.opacity(0);
				.scale(0);
			}
		}
	
		// Chat Close
		.chat-close {
			position: absolute;
			right: 0px;
			font-size: 15px;
			right: @padding_h - 10px;
			margin-top: 0px;
			color: @default-border-color;
			font-weight: normal;
			.opacity(.8);
			
			&:hover {
				.opacity(1);
			}
		}
	}
	
	.chat-group {
		margin-top: @padding_v - 5;
		
		> strong, > a {
			display: block;
			padding: 6px @padding_h;
			font-weight: normal;
			font-size: @font-size-base - 1;
		}
		
		> strong {
			text-transform: uppercase;
			color: @main-text-color;
		}
		
		> a {
			position: relative;
			color: @main-text-color;
			.transition(~"background 250ms ease-in-out");
			
			&:hover, &.active {
				color: darken(@main-text-color, 15%);
			}
			
			&:before {				
				content: '';
				display: block;
				position: absolute;
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 4px 0 4px 4px;
				border-color: transparent transparent transparent @menu_active_color;
				left: 0;
				top: 50%;
				margin-top: -4px;
				.opacity(0);
				.transall;
			}
			
			
			&.active {
				
				&:before {
					.opacity(1);
				}
			}
			
			.badge {
				font-size: 9px;
				margin-left: 5px;
				.transform(~"scale(1)");
				.opacity(1);
				.transall;
				
				&.is-hidden {
					.opacity(0);
					.scale(0);
				}
			}
			
			em {
				font-style: normal;
			}
		}
	}
			
	.user-status {
		@s: 8px;
		
		display: inline-block;
		background: @status-offline;
		margin-right: 5px;
		.size(@s);
		.border-radius(@s);
		.transall;
		
		&.is-online { background-color: @brand-success; }
		&.is-offline { background-color: @status-offline; }
		&.is-idle { background-color: @brand-warning; }
		&.is-busy { background-color: @brand-danger; }
	}
	
	
	// Chat Conversation
	.chat-conversation {
		@padd: 20px 24px;
		
		position: absolute;
		right: @chat-width - 1;
		width: @chat-converation-width;
		background: @main_color;
		border: 1px solid #dedede;
		top: 166px;
		display: none;
		//opacity: 0;
		
		.page-body.right-sidebar & {
			right: auto;
			left: @chat-width - 1;
		}
		
		&.is-open {
			display: block;
		}
		
		
		.conversation-header {
			position: relative;
			padding: @padd;
			font-size: 14px;
			color: @main-text-color;
			border-bottom: 1px solid @border;
			
			small {
				color: fade(@main-text-color, 60%);
				font-size: 12px;
				padding-left: 8px;
			}
			
			.conversation-close {
				float: right;
				color: @main-text-color;
				.opacity(1);
				position: relative;
				top: -2px;
			}
		
			&:after {
				@s: 7px;
				@sw: @s * 1.3;
				
				position: absolute;
				display: block;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: @s 0 @s @sw;
				border-color: transparent transparent transparent #dedede;
				right: -@sw;
				top: 50%;
				margin-top: -@s/2;
				
				.page-body.right-sidebar & {
					right: auto;
					left: -@sw + 1;
					border-width: @s @s @sw 0;
					border-color: transparent #dedede transparent transparent;
				}
			}
		}
		
		.conversation-body {
			list-style: none;
			margin: 0;
			padding: 0;
			overflow: auto;
			height: 250px - 5;
			
			&::-webkit-scrollbar {
		            width: 5px;
		    }
		    &::-webkit-scrollbar-track {
		            width: 5px;
		            background-color: #fff;
		    }
		    &::-webkit-scrollbar-thumb {
		            background-color: #dedede;
		    }
			
			> li {
				padding: 0 30px;
				margin: 0;
				padding-top: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid #f1f1f1;
				color: @main-text-color;
				&:extend(.clearfix all);
				
				&.odd {
					background: fade(#fafafa, 55%);
				}
				
				.user {
					font-weight: bold;
					color: darken(@main-text-color, 10%);
				}
				
				.time {
					float: right;
					color: fade(@main-text-color, 80%);
					font-size: @font-size-base - 3;
					position: relative;
					top: 3px;
				}
				
				p {
					padding-top: 5px;
				}
				
				&:last-child {
					border-bottom: 0;
				}
			} 
		}
		
		.chat-textarea {
			padding: @padd;
			background: #fafafa;
			position: relative;
			border-top: 1px solid #ededed;
			padding: 0;
			
			&:after {
				content: '\f075';
				font-family: 'fontawesome';
				color: @main-text-color;
				right: 15px;
				top: 10px;
				font-size: 15px;
				position: absolute;
			}
			
			textarea {
				background: #fafafa;
				border: 0;
				max-height: 100px !important;
				padding-right: 35px;
				padding-top: 10px;
				height: 32px;
				resize: none;
				.placeholder(fade(@main-text-color, 85%));
				
				&::-webkit-scrollbar {
			            width: 5px;
			    }
			    &::-webkit-scrollbar-track {
			            width: 5px;
			            background-color: #ededed;
			    }
			    &::-webkit-scrollbar-thumb {
			            background-color: #ccc;
			    }
			}
		}
	}
}


.chat-history {
	display: none;
}



/* Relatively-Small screen */
@media (max-width: @screen-xs-max) {
	
	@border: #343a45;
	
	.chat-visible {
		
		#chat, #chat.fixed {
			display: none;
		}
		
		&.toggle-click {
			
			#chat, #chat.fixed {
				display: block;
			}
		}
	}
	
	
	#chat, #chat.fixed {
		
		position: fixed;
		width: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 10px;
		//.box-shadow(0px 0px 0px 20px fade(#fff, 50%));
		//.rounded;
		
		.chat-conversation {
			position: fixed;
			width: auto;
			left: 0;
			right: 0;
			top: 0 !important;
			bottom: 0;
			margin: 10px;
			overflow: auto;
			//.rounded;
			
			.conversation-body {
				height: auto;
				max-height: 450px;
			}
						
			&::-webkit-scrollbar {
		            width: 5px;
		    }
		    &::-webkit-scrollbar-track {
		            width: 5px;
		            background-color: @border;
		    }
		    &::-webkit-scrollbar-thumb {
		            background-color: lighten(@border, 10%);
		    }
		}
	}
}