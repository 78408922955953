/* 按钮 */
.ui-form {
  .form-btn{
    margin-bottom: 0;
    padding: 4px 10px;
  }
  .btn{
     margin-bottom: 0;
  }
  .btn+.btn{
    margin-left: 5px;
  }
}

