/* 表单对齐 */
.ui-form {

  label {
    margin-bottom: 0;
  }
  .label {
    font-weight: normal;
  }
  .cursor{
    cursor:pointer;
  }

  /* 下垃框外的文本行高 */
  .controls {
    &.two-rows, &.three-rows {
      .line-select {
        display: inline-block;
        text-align: right;
        width: @left-middle-width;
      }
      &.rows-width-small {
        .line-select {
          width: @default-left-width;
        }
      }
    }
  }
  .line-select {
    line-height: @input-height-base;
  }

}

/* 特殊行高处理 */
.line-select {
  &.tips-inline {
    line-height: @input-height-base;
  }
  line-height: @input-height-base;
}

/* 手型代码放开ui-form限制 */
.cursor {
  cursor: pointer;
}

/* 元素间距放开ui-form限制 */

/* 小的padding值 */
.padding-lr-small {
  padding: 0 @small-padding-default;
}

.padding-l-small {
  padding-left: @small-padding-default
}

.padding-r-small {
  padding-right: @small-padding-default;
}

.padding-t-small {
  padding-top: @small-padding-default;
}

.padding-b-small {
  padding-bottom: @small-padding-default;
}

/* 大的padding值 */
.padding-lr-large {
  padding: 0 @large-padding-default;
}

.padding-l-large {
  padding-left: @large-padding-default
}

.padding-r-large {
  padding-right: @large-padding-default;
}

.padding-t-large {
  padding-top: @padding-default;
}

.padding-b-large {
  padding-bottom: @padding-default;
}

/* 正常大小的padding值 */
.padding-lr {
  padding: 0 @padding-default;
}

.padding-l {
  padding-left: @padding-default;
}

.padding-r {
  padding-right: @padding-default;
}

.padding-t {
  padding-top: @padding-default;
}

.padding-b {
  padding-bottom: @padding-default;
}

/* 正常大小的margin值 */
.margin-lr {
  margin: 0 @margin-default;
}

.margin-l {
  margin-left: @margin-default;
}

.margin-right {
  margin-right: @margin-default;
}

.margin-t {
  margin-top: @margin-default;
}

.margin-b {
  margin-bottom: @margin-default;
}

/* 小的margin值 */
.margin-lr-small {
  margin: 0 @small-margin-default;
}

.margin-l-small {
  margin-left: @small-margin-default;
}

.margin-r-small {
  margin-right: @small-margin-default;
}

.margin-t-small {
  margin-top: @small-margin-default;
}

.margin-b-small {
  margin-bottom: @small-margin-default;
}

/* 大的margin值 */
.margin-lr-large {
  margin: 0 @large-margin-default;
}

.margin-l-large {
  margin-left: @large-margin-default;
}

.margin-r-large {
  margin-right: @large-margin-default;
}

.margin-t-large {
  margin-top: @large-margin-default;
}

.margin-b-large {
  margin-bottom: @large-margin-default;
}

.btn-left-margin{
  margin-left:-1px !important;
}