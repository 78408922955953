/* 下拉框 */

select {
  &.base-select{
    &:focus{
      border:1px solid @input-border;
      outline: 0;
    }
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAYCAYAAADtaU2/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2lpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo5Q0Q0MkU5NjRFNDExMUU2OURFM0M1MzFCMjE4QkZBOCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NEM2NEIzMTRFNDQxMUU2OUM5NEVGMDJBQTdGNkUxOSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NEM2NEIzMDRFNDQxMUU2OUM5NEVGMDJBQTdGNkUxOSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTBhOWY3MTYtZTJlOS1mYTQzLWFmMDItM2Q2MTczYmQyYzI3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjlDRDQyRTk2NEU0MTExRTY5REUzQzUzMUIyMThCRkE4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+BHKqPgAAAJRJREFUeNpi/P//P8NAACaGAQKjFo9aPGox1QALsQo7OzuLgVQNEP8E4r9ojucE4rby8vIuqlsMBHOBWAuIk7DILQbi2aT4mJGUIhPoa1EgNQ2IQ5CENwBxOtC3r2hmMdRyeajvnYH4ABAnAi19QGocM5JTSQAttwVSqSAHAC09SE7iYhytnUYtHrV41OIhZzFAgAEAEq4piHIbX3oAAAAASUVORK5CYII=') no-repeat right 3px #fff !important;
    border:1px solid @input-border;
    line-height: 1.2;
    padding:6px 15px 6px 6px;
    border-radius: 0;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    cursor: pointer;
    _background-image: none;
    /*清除ie的默认选择框样式清除，隐藏下拉箭头*/
    &::-ms-expand { display: none; }
  }

    height: @input-height-base;
    &.select-xmini {
      width: @input-xmini-width;
    }
    &.select-mini {
      width: @input-mini-width;
    }
    &.select-small {
      width: @input-small-width;
    }
    &.select-middle {
      width: @input-middle-width;
    }
    &.select-large {
      width: @input-large-width;
    }
    &.select-xlarge {
      width: @input-xlarge-width;
    }
    &.select-xxlarge {
      width: @input-xxlarge-width;
    }
    + .selectboxit-container {
      //  height: @selectBoxIt-height;
      display: inline-block;
    }
  }



  .selectboxit-container .selectboxit {
    padding-right: @selectBoxIt-arrow-padding;
  }

  .select-xmini {
      &.selectboxit {
        width: @selectBoxIt-xmini-width !important;
        .selectboxit-text {
          width: @selectBoxIt-xmini-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-mini {
      &.selectboxit {
        width: @selectBoxIt-mini-width !important;
        .selectboxit-text {
          width: @selectBoxIt-mini-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-small {
      &.selectboxit {
        width: @selectBoxIt-small-width !important;
        .selectboxit-text {
          width: @selectBoxIt-small-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-middle {
      &.selectboxit {
        width: @selectBoxIt-middle-width !important;
        .selectboxit-text {
          width: @selectBoxIt-middle-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-large {
      &.selectboxit {
        width: @selectBoxIt-large-width !important;
        .selectboxit-text {
          width: @selectBoxIt-large-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-xlarge {
      &.selectboxit {
        width: @selectBoxIt-xlarge-width !important;
        .selectboxit-text {
          width: @selectBoxIt-xlarge-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }
  .select-xxlarge {
      &.selectboxit {
        width: @selectBoxIt-xxlarge-width !important;
        .selectboxit-text {
          width: @selectBoxIt-xxlarge-width - @selectBoxIt-arrow-padding - @selectBoxIt-option-left !important;
        }
      }
  }

//水平表单三级下拉样式问题补丁
.ui-form {
  &.page-inline {
    .select2-container {
      line-height: normal;
    }
  }
}



