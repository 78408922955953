.badge {
	position: relative;
	font-size: 9px;
	line-height: 12px;
	
	&.pull-right {
		margin-top: 4px;
		margin-left: @base-padding/2;
	}
	
	&.pull-left {
		margin-top: 4px;
		margin-right: @base-padding/2;
	}
	
	&.badge-default {
		background-color: #777777;
	}
	
	&.badge-red,
	&.badge-danger {
		background-color: @brand-danger;
	}
	
	&.badge-blue,
	&.badge-info {
		background-color: @brand-info;
	}
	
	&.badge-yellow,
	&.badge-warning {
		background-color: @brand-warning;
	}
	
	&.badge-green,
	&.badge-success {
		background-color: @brand-success;
	}
	
	&.badge-primary {
		background-color: @brand-primary;
	}
	
	&.badge-secondary {
		background-color: @secondary-color;
	}
	
	&.badge-purple {
		background-color: @xe-purple;
	}
	
	&.badge-pink {
		background-color: @xe-pink;
	}
	
	&.badge-yellow {
		background-color: @xe-yellow;
	}
	
	&.badge-orange {
		background-color: @xe-orange;
	}
	
	&.badge-turquoise {
		background-color: @xe-turquoise;
	}
	
	&.badge-red {
		background-color: @xe-red;
	}
	
	&.badge-blue {
		background-color: @xe-blue;
	}
	
	&.badge-black {
		background-color: @xe-black;
	}
	
	&.badge-white {
		background-color: #fff;
		color: #333;
		.box-shadow(0px 0px 0px 1px @default-border-color);
	}
}