/* 文本域 */
.ui-form {
  textarea {

    width: @default-textarea-width;
    &.textarea-small {
      width: @textarea-small-width;
    }
    &.textarea-middle {
      width: @textarea-middle-width;
    }
    &.textarea-large {
      width: @textarea-large-width;
    }
    &.textarea-xlarge {
      width: @textarea-xlarge-width;
    }
  }
}

// TODO 标签位置先放这
/* 可删除标签 */
.tag-label{
  .label{
    padding:@small-padding-default @padding-default;
    line-height: normal;
  }
}
.tag-unite {
  border: 1px solid @table-border-color;
  margin-bottom: @margin-default;
  margin-right: @margin-default;
  padding:@small-padding-default @padding-default;
  color: @text-color;
  display: inline-block;
  cursor: pointer;
  i {
    color: @tag-close-color;
    padding-left: @padding-default;
  }
  &.border-primary{
    border-color:@brand-primary;
  }
  &.border-warning{
    border-color:@brand-warning;
  }
  &.border-danger{
    border-color:@brand-danger;
  }
  &.add-part{
    padding:@small-padding-default 2*@large-padding-default;
    i{
      padding-left:0;
    }
    border-style: dashed;
  }
}