.ui-form {
  input[type="radio"], input[type="checkbox"] {
    &.ui-input {
      + label {
        line-height: @input-height-base;
        margin-right: @margin-default;
        &.line-text {
          line-height: normal;
        }
      }
    }
  }
}

&.ui-input {
  + label {
    margin-right: @margin-default;
  }
}