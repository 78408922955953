/* 提示信息 */
.tips-inline {
  display: inline-block;
  margin-left: @margin-default;
  line-height: 1.42857143;
  i{
    margin-right:@small-margin-default;
  }
}

.tips-block {
  display: block;
  margin-left: @small-margin-default;
  margin-top: @controls-left-padding;
  line-height: 1.42857143;
  i{
    margin-right:@small-margin-default;
  }
}

.tips-tooltip{
  width:@default-tips-width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor:pointer;
  &.tips-mini{
    width:@mini-tips-width;
  }
  &.tips-middle{
    width:@middle-tips-width;
  }
  &.tips-large{
    width:@large-tips-width;
  }
}

.text-info, .text-warning, .text-danger {
  > i.fa-question-circle {
    cursor: pointer;
  }
}

//必选样式
.require-icon:before {
  content: '*';
  color: @brand-danger;
  position: relative;
  right: @small-padding-default;
}