/* 日期格式 */

.ui-form {
  input[type=text] {
    &.input-time {
      width: @data-default-width;
    }
    &.year-time {
      width: @data-year-width;
    }
  }
  a > {
    .fa-calendar {
      color: @date-icon-color;
    }
  }

}

.input-time, .year-time {
  cursor: pointer;
  + .input-group-addon {
    cursor: pointer;
  }
}

