nav.navbar {
	border: 0;
	
	form > .btn {
		margin-bottom: 0;
	}
	
	.navbar-header {
		
		.navbar-brand {
			padding-left: @default-padding;
			padding-right: @default-padding;
		}
	}
	
	.navbar-nav {
		
		li {
			
			a {
				padding-left: @base-margin;
				padding-right: @base-margin;
			}
		}
	}
	
	&.navbar-inverse {
		
		.navbar-toggle {
			
			i {
				color: #fff;
			}
		}
	}
}