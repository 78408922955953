/* 文本框 */
.ui-form {
  input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    width: 220px;
    height: @input-height-base;
    word-break: normal;

    &.input-xmini {
      width: @input-xmini-width;
    }
    &.input-mini {
      width: @input-mini-width;
    }
    &.input-small {
      width: @input-small-width;
    }
    &.input-middle {
      width: @input-middle-width;
    }
    &.input-large {
      width: @input-large-width;
    }
    &.input-xlarge {
      width: @input-xlarge-width;
    }
    &.input-xxlarge {
      width: @input-xxlarge-width;
    }
  }
}

