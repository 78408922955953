.overflow-hidden {
  overflow: hidden !important;
}

.vertical-top {

  > * {
    vertical-align: top;
  }
}

strong + table {
  margin-top: @base-padding;
}

.text-small {
  font-size: 65%;
}

// Bold
.text-bold {
  font-weight: bold;
}

// Address
address {
  padding-left: @base-margin;
  border-left: 5px solid #f0f0f0;
}

// Scrollable inside Scrollable
.scrollable .scrollable {
  margin-right: @base-margin;
}

// Super Large Text
.super-large {
  font-size: @font-size-h1 * 2;
}

// Inline
.inline {
  display: inline-block !important;
}

div.text-overflow {
  .text-overflow();
  max-width: 100%;
}

.list-margin {
  margin: @base-padding 0;

  li {
    padding: @base-padding/2 0;
  }
}

.rotate-45deg {
  position: relative;
  .rotate(45deg);
}

.text-medium {
  font-size: @font-size-small;
}

.text-bold {
  font-weight: bold;

  &.h1,
  &.h2,
  &.h3,
  &.h4,
  &.h5,
  &.h6 {
    font-weight: bold;
  }
}

.text-upper {
  text-transform: uppercase;
}

// Chart Item
.chart-item-bg {
  position: relative;
  background: #fff;
  width: 100%;
  margin-bottom: @default-padding;

  .chart-label {
    position: absolute;
    left: 0;
    top: 0;
    padding: @default-padding;
    font-size: @font-size-base + 2;

    &.chart-label-small {
      padding: @base-margin;
    }

    .h1, .h2, .h3, .h4 {
      margin: 0;
      padding: 0;
    }
  }

  .chart-right-legend {
    .chart-item-bg > .chart-label;
    left: auto;
    right: 0;
  }

  .chart-inner {
    padding: @base-margin;
  }

  .chart-inner-wide {
    padding: @base-margin @default-padding + 10;
  }

  .chart-entry-view {
    border-top: 1px solid #eeeeee;
    display: table;
    table-layout: fixed;
    width: 100%;

    > div {
      display: table-cell;
      vertical-align: middle;
      padding: @base-margin @default-padding;
      color: @main-text-color;
      font-size: @font-size-base + 1;
    }

    &:hover {
      background: #fafafa;
    }
  }
}

.chart-item-bg-2 {
  background: #fff;
  display: table;
  width: 100%;
  margin-bottom: @default-padding;

  > div {
    display: table-cell;
    padding: @default-padding/2;
    vertical-align: middle;
  }

  .chart-item-num {
    padding-left: 40px;
    font-size: 90px;
    color: darken(@main-text-color, 33%);
    padding-right: @default-padding;
    white-space: nowrap;

    &.num-sm {
      font-size: 70px;
    }
  }

  .chart-item-env {

    > div {
      margin: @base-padding 0;
    }
  }
}

// Skin Color Palette
.skin-color-palette {
  display: block;
  border: 1px solid #f0f0f0;
  margin-right: @base-margin;
  &:extend(.clearfix all);

  > span {
    float: left;
    width: 100% / 6;
    height: 20px;

    + span {
      border-left: 0px solid #fff;
    }
  }

  &.skin-color-palette-four {

    > span {
      width: 100% / 4;
    }
  }
}

.skin-name-link {
  font-weight: bold;
  color: @text-color;

  &:hover {
    color: @secondary-color;
  }
}

// BS Example
.bs-example {
  line-height: 1.85;

  .label {
    margin-left: 2px;
  }
}

.same-margin {

  > * {
    margin-top: 0;
    margin-bottom: @base-padding+5;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.vspacer {
  height: 80px;

  &.v2 {
    height: 15px;
  }

  &.v3 {
    height: @default-padding;
    display: block !important;
  }

  &.v4 {
    height: @base-padding;
    display: block !important;
  }

  @media screen and (max-width: @screen-sm) {
    display: none;

    &.v2 {
      display: block;
    }
  }
}

// Image Container
.img-container {
  margin-bottom: @base-margin;
}

.img-shade {
  display: block;
  background: #f5f5f5;
  text-align: center;
  line-height: 0;
  margin-bottom: @base-margin;
}

.img-preview {
  display: inline-block;
  overflow: hidden;
  background: #f7f7f7;
  line-height: 1;

  img {
    display: block;
  }
}

.no-left-border {
  border-left: 0 !important;
}

.no-right-border {
  border-right: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-transition {
  .transition(none) !important;
}

// Circle image
img.img-cirlce {
  .border-radius(50%);
}

// Column margin
.col-margin.row {

  > * {
    margin-bottom: @base-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ckeditor + .cke {
  margin-top: @base-margin;
  margin-bottom: @default-padding;
}

*[class*="fa-"] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Scrollable
div.scrollable {
  max-height: 200px;
  overflow: hidden;
  position: relative;
}

// icon-collection
.icon-collection {

  .page-header {
    margin: 0;
    margin-bottom: @base-padding;
  }

  section .page-header {
    margin-top: @default-padding + @base-padding;
  }

  .fontawesome-icon-list {
    &:extend(.clearfix all);

    .fa-hover {
      background: #fff;
      float: left;
      width: 24%;
      margin-right: @base-padding;
      margin-bottom: @base-padding;
      border: 1px solid @default-border-color;
      &:extend(.clearfix all);

      &:nth-child(4n) {
        margin-right: 0;
      }

      @media (max-width: @screen-sm-max) {
        width: 31%;

        &:nth-child(3n) {
          margin-right: 0;

          i {

            &:hover {
              margin: 0;
            }
          }
        }

        &:nth-child(4n) {
          margin-right: @base-padding;
          clear: right;
        }
      }

      @iwidth: 25%;

      > i {
        display: block;
        float: left;
        position: relative;
        background: #fafafa;
        border-right: 1px solid lighten(@input-border, 2%);
        padding: @base-padding;
        text-align: center;
        width: @iwidth;
        &:extend(.transall);

        &:hover {
          width: 60%;
          background: @secondary-color;
          color: #fff;
          margin: -1px;

          + span {
            width: 40%;
          }
        }
      }

      > span {
        display: block;
        float: right;
        .text-overflow();
        padding: @base-padding;
        padding-bottom: @base-padding - 2;
        width: 100% - @iwidth;
        color: darken(@main-text-color, 10%);
        &:extend(.transall);

        &:hover {
          color: @text-color;
        }
      }
    }
  }
}

// Layout Variants
.layout-variants {
  background: #fff;
  padding: @default-padding;
  //margin-top: @base-margin;
  margin-bottom: @default-padding;

  .row-clearer {
    clear: both;
    height: @default-padding/2;
  }

  .layout-variant {
    display: block;
    margin-bottom: @base-margin;
    padding: @default-padding/2;
    border: 1px solid transparent;

    &:hover, &.layout-current {
      background: fade(#f0f0f0, 20%);
      border-color: #f0f0f0;

      .layout-name a {
        color: @text-color;
      }
    }

    .layout-img {
      margin-bottom: @base-margin - 5;

      img {
        display: block;
        width: 100%;
      }
    }

    .layout-name {
      font-weight: bold;

      a {
        color: lighten(@text-color, 25%);
        max-width: 248px - 30;
        display: block;
        .text-overflow();
      }

      &:after {
        display: block;
        content: '';
        width: 20px;
        height: 1px;
        background: #ccc;
        margin: @base-padding/1.7 0;
      }
    }

    .layout-links {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 2px 0;

        a {
          color: @main-text-color;
          font-size: @font-size-small - 2;
          text-transform: uppercase;

          &:hover,
          &.layout-mode-current {
            color: darken(@main-text-color, 30%);
          }

          &.layout-mode-current {
            font-weight: bold;
          }

          &.disabled {
            cursor: not-allowed;
            color: @main-text-color;
          }
        }
      }
    }
  }
}

// Loader
@loader-1-size: 30px;
@loader-1-color: #555;
@loader-1-duration: 1000ms;

.loader-1 {
  .animation(~"loaderAnimate @{loader-1-duration} linear infinite");
  clip: rect(0, @loader-1-size, @loader-1-size, @loader-1-size/2);
  height: @loader-1-size;
  width: @loader-1-size;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -@loader-1-size/2;
  margin-top: -@loader-1-size/2;
}

.keyframes(~"loaderAnimate, 0%{ transform: rotate(0deg); } 100%{ transform: rotate(220deg); }");
.keyframes(~"loaderAnimate2, 0%{ box-shadow: inset @{loader-1-color} 0 0 0 8px; transform: rotate(-140deg); } 50%{ box-shadow: inset @{loader-1-color} 0 0 0 2px; } 100%{ box-shadow: inset @{loader-1-color} 0 0 0 8px; transform: rotate(140deg); }");

.loader-1:after {
  .animation(~"loaderAnimate2 @{loader-1-duration} ease-in-out infinite");
  clip: rect(0, @loader-1-size, @loader-1-size, @loader-1-size/2);
  content: '';
  border-radius: 50%;
  height: @loader-1-size;
  width: @loader-1-size;
  position: absolute;
}

@keyframes loaderAnimate2 {
  0% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(140deg);
  }
}

// Loader 2
@loader-2-size: 30px;
@loader-2-color: #fff;
@loader-2-duration: 1000ms;

.loader-2 {
  .animation(~"loaderAnimate2 @{loader-2-duration} linear infinite");
  clip: rect(0, @loader-2-size, @loader-2-size, @loader-2-size/2);
  height: @loader-2-size;
  width: @loader-2-size;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -@loader-2-size/2;
  margin-top: -@loader-2-size/2;
}

.keyframes(~"loaderAnimate2, 0%{ transform: rotate(0deg); } 100%{ transform: rotate(220deg); }");
.keyframes(~"loaderAnimate22, 0%{ box-shadow: inset @{loader-2-color} 0 0 0 8px; transform: rotate(-140deg); } 50%{ box-shadow: inset @{loader-2-color} 0 0 0 2px; } 100%{ box-shadow: inset @{loader-2-color} 0 0 0 8px; transform: rotate(140deg); }");

.loader-2:after {
  .animation(~"loaderAnimate22 @{loader-2-duration} ease-in-out infinite");
  clip: rect(0, @loader-2-size, @loader-2-size, @loader-2-size/2);
  content: '';
  border-radius: 50%;
  height: @loader-2-size;
  width: @loader-2-size;
  position: absolute;
}

@keyframes loaderAnimate22 {
  0% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(140deg);
  }
}

.page-loading-overlay {
  position: fixed;
  left: @sidebar-width;
  top: 55px;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 10000;
  .opacity(1);
  .transition(~"all 800ms ease-in-out");

  &.loaded {
    .opacity(0);
    visibility: hidden;
  }
}

/* 加载效果 */
.loadingCircle {
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  position: absolute;

  .circle {
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 35px #2187e7;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    bottom: 0;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite ease-in-out;
    -o-animation: spinPulse 1s infinite ease-in-out;
    -ms-animation: spinPulse 1s infinite ease-in-out;

  }
  .circle1 {
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 15px #2187e7;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    position: absolute;
    left: 10px;
    bottom: 10px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;
    -o-animation: spinoffPulse 1s infinite linear;
    -ms-animation: spinoffPulse 1s infinite linear;
  }
  @-moz-keyframes spinPulse {
    0% {
      -moz-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #505050;
    }
    50% {
      -moz-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -moz-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-moz-keyframes spinoffPulse {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinPulse {
    0% {
      -webkit-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #505050;
    }
    50% {
      -webkit-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes spinoffPulse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-o-keyframes spinPulse {
    0% {
      -o-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #505050;
    }
    50% {
      -o-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -o-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-o-keyframes spinoffPulse {
    0% {
      -o-transform: rotate(0deg);
    }
    100% {
      -o-transform: rotate(360deg);
    }
  }
  @-ms-keyframes spinPulse {
    0% {
      -ms-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #505050;
    }
    50% {
      -ms-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -ms-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-ms-keyframes spinoffPulse {
    0% {
      -ms-transform: rotate(0deg);
    }
    100% {
      -ms-transform: rotate(360deg);
    }
  }
}

.page-loading-overlay.sub {
  background-color: rgba(0, 0, 0, 0);
  height: 2px;
  left:0;
  top:0;
  .splashscreen {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
  }
 /*  浏览器顶部栏加载中状态提示 —— 样式暂时注释*/
 /*
 .progressBarAni {
    height: 2px;
    background-color:#f39a00;
    position: absolute;
    width: 100%;
    -moz-animation: proBar 2s ease-out infinite;
    -webkit-animation: proBar 2s ease-out infinite;
  }
  */

  @-moz-keyframes proBar {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }

  @-webkit-keyframes proBar {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
}