/* 表单布局 */
.ui-form {
  width: 100%;
  font-size: @font-size-small;
  &.page-inline{
    .control-group{
      display: inline-table;
      margin-right:@line-group-margin;
      &:last-child{
        margin-right:0;
      }
      .control-label{
        width:auto;
        float: left;
      }
      .controls {
        float: left;
        line-height: @input-height-base;
      }
      .btn-group {
        float: left;
        .btn-xs {
          position: relative;
          top: -4px;
        }
      }
      .btn {
        float: left;
      }
    }
  }
  &.text-line-form {
    .control-label {
      line-height: normal;
    }
  }
  &.form-xmini {
    .control-label {
      width: @left-xmini-width;
    }
  }
  &.form-mini {
    .control-label {
      width: @left-mini-width;
    }
  }
  &.form-middle {
    .control-label {
      width: @left-middle-width;
    }
  }
  &.form-large {
    .control-label {
      width: @left-large-width;
    }
  }
  &.v-top {
    .control-label {
      vertical-align: top;
    }
  }
  &.v-middle {
    .control-label {
      vertical-align: middle;
    }
  }
  &.v-bottom {
    .control-label {
      vertical-align: bottom;
    }
  }

  .control-group {
    margin-bottom: @line-group-margin;
    display: table;
  }
  .control-label {
    color: @text-color;
    width: @default-left-width;
    text-align: @text-align;
    display: table-cell;
    padding-top: 0;
    line-height: @input-height-base;
    &.line-text {
      line-height: normal;
    }
    &.v-top {
      vertical-align: top;
    }
    &.v-middle {
      vertical-align: middle;
    }
    // line-height: @form-line-height;
  }
  .controls {
    display: table-cell;
    padding-left: @controls-left-padding;
    .onoffswitch{
      top: @padding-small-vertical;
    }
    &.width-normal {
      width: @controls-width-normal;
    }
    .label-block-field {
      border: 1px solid @input-border;
      border-bottom: none;
      background: @table-bg-hover;
      padding: @small-padding-default @small-padding-default 0 @small-padding-default;
      width: @label-space-default;
      &.label-middle {
        width: @label-space-middle;
      }
      &.label-large {
        width: @label-space-large;
      }
      label {
        display: inline-block;
        cursor: pointer;
        border: 1px solid transparent;
        color: @text-color;
        font-size: @font-size-small;
        margin-bottom: @small-margin-default;
        &:visited {
          border-color: @input-border;
          background: #fff;
        }
        &:hover {
          border-color: @input-border;
        }
        &:active {
          border-color: @input-border;
          background: #fff;
        }
      }
    }
    .controls-block{
      border: 1px solid @input-border;
      padding:@form-block-padding;
      min-width: @textarea-middle-width;
      &.dashed-border{
        border-style: dashed;
      }
      table{
        &.dataTable{
          margin-top:0 !important;
          margin-bottom:0 !important;
        }
        table-layout: fixed;
        margin-bottom:0;
        >tbody
        >tr
        >td{
          &:last-child{
            cursor: pointer;
          }
        }
      }
      .goods-content{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .nodata-tips{
        padding-top:@form-block-padding;
        margin:0;
        text-align: center;
        font-size: 1.2em;
        color:@secondary-text-color;
      }
    }
    > * {
      // line-height: @form-line-height;
    }
    &.v-middle {
      vertical-align: middle;
    }

     /* 表单域行间距模式调整 */
      .controls-line{
     + .controls-line {
     margin-top: @margin-default;
        &.label-top {
          margin-top: @label-group-margin;
        }
      }
    }
    .unite-inline {
      display: inline-block;
      float: left;
     /* margin-bottom: @small-margin-default;*/
      margin-right:@margin-default;
      >.unite-inline{
        margin-bottom:0;
      }

   /*   + .unite-inline {
        margin-left: @margin-default;
      }*/
      &.margin-r-large {
        margin-right: @large-margin-default;
      }
    }

    .form-control {
      display: inline-block;
      &.selectboxit{
        display: block;
      }
    }
  }
 /* 清除浮动 */
  .controls-line,.controls-block{
    *zoom: 1;
    &:before {
      content: '';
      display: table;
    }
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  &:before{
    content:'';
    display: table;
  }
  &:after{
    content:'';
    display: table;
  }
  &:after{
    clear:both;
  }
  *zoom:1;
}
.pull-right {
  *zoom: 1;
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* 元素边框结构 */
.block-border {
  border-width: @border-width;
  border-style: @border-style;
  border-color: @border-color;
  &.border-primary {
    border-color: @border-primary-color;
    &.dashed {
      border-style: @border-dashed;
    }
  }
  &.border-info {
    border-color: @border-info-color;
    &.dashed {
      border-style: @border-dashed;
    }
  }
  &.border-success {
    border-color: @border-success-color;
    &.dashed {
      border-style: @border-dashed;
    }
  }
  &.border-warning {
    border-color: @border-warning-color;
    &.dashed {
      border-style: @border-dashed;
    }
  }
  &.border-danger {
    border-color: @border-danger-color;
    &.dashed {
      border-style: @border-dashed;
    }
  }
}
